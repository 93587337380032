.container {
  box-sizing: border-box;
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    font-size: large;
  }
}

.stockTable {
  tr {
    grid-template-columns: 5fr 5fr 9fr 27fr repeat(6, 9fr);
  }

  .buttonBlock {
    padding: 0;

    button {
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.itemsEditorContainer {
  .itemEditorOptions {
    display: flex;

    .option {
      border: none;
      border-bottom: 3px solid #E2CCFF;
      border-radius: 0;
      padding: 6px 12px;
      font-size: normal;
      text-decoration: none;
      text-align: center;
      color: #7029CC;

      &:visited {
        color: #7029CC;
      }

      &:hover {
        background: #E2CCFF;
        border-color: #E2CCFF;
      }

      &:active {
        background: #d4b3ff;
        border-color: #d4b3ff;
      }
    }

    .option.active {
      border-color: #ad66ff;
    }
  }
}