@import '../../variables.scss';

table {
  
  &, thead, tbody, tfoot {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: overlay;
  }

  &, tbody {
    flex-shrink: 1;
    flex-grow: 1;
  }

  tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  border: 1px solid #E2CCFF;

  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 2px;
  background-color: hsl(268, 100%, 97%);
  color: inherit;
  font-size: 16px;

  td, th {
    box-sizing: border-box;
    padding: 4px 8px;
    min-height: 30px;
    border: 1px solid #E2CCFF;
    background-color: $page-background;
    font-weight: normal;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  thead, tfoot {
    td, th {
      background-color: #F1E5FF;
    }
  }
}