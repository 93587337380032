.container {
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
	user-select: none;
  padding: 24px;
	margin-top: 20vh;
  text-align: center;

	.title {
		color: #B780FF;
		font-size: 36px;
		font-weight: 700;
	}
	.subtitle {
		color: #C599FF;
		font-size: 22px;
		font-weight: 700;
		margin-top: 0;
	}
	.secondaryTitle {
		color: hsl(0, 0%, 50%);
		font-size: 16px;
		margin-top: 36px;
	}
  .actionButtons {
		margin-top: 36px;
  }
	.message {
		color: hsl(0, 0%, 30%);
		font-size: 16px;
		font-weight: 400;
	}
	.linkLabel {
		color: hsl(0, 0%, 30%);
		font-size: 16px;
		font-weight: 400;
		margin-top: 36px;
	}
	.link {
		display: block;
		color: #A866FF;
		text-decoration: none;
		font-size: 18px;
		font-weight: 400;
		margin-top: 8px;
	}
}