@import './variables.scss';

.app {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: $body-background;
}

@mixin gradient-bar {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 200;
  background: linear-gradient(-90deg, hsl(0, 100%, 50%), hsl(266, 100%, 50%), hsl(150, 100%, 50%), hsl(324, 100%, 50%));
  background-size: 250% 100%;
  animation:
        gradientAnimation 2s ease-in-out infinite alternate-reverse
      , makeVisible 0.3s ease-out forwards;
  height: 4px;
}

.loading-top, .loading-bottom {
  position: relative;
}

.loading-top::after {
	@include gradient-bar;
  top: 0;
}
.loading-bottom::after {
	@include gradient-bar;
  bottom: 0;
}

.loading-shine {
  background: linear-gradient(110deg, transparent 8%, hsla(0, 0%, 50%, 0.1) 18%, transparent 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes gradientAnimation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes makeVisible {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}