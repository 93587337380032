@import '../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $page-background;
  overflow-x: hidden;
  padding-bottom: 16px;
  border-radius: 6px;

  .header {
    position: sticky;
    top: 0;
    background-color: inherit;
    font-weight: 500;
    font-size: 22px;
    color: #8D33FF;
    padding: 16px;
  }

  .subnav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    // .subnavSection {
    //   flex-grow: 1;
    // }
  }
}

.subnavOption {
  background: none;
  text-decoration: none;
  border: none;
  color: inherit;
  font-style: inherit;
  font-size: inherit;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  user-select: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  text-align: left;

  &:hover {
    color: darken(#e2ccff, 30);
  }

  &:active {
    background-color: lighten(#e2ccff, 3);
  }
}

.subnavText {
  user-select: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
}

.selected {
  background-color: #e2ccff;
}