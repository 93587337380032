.form {
  display: flex;
  align-items: stretch;
  input, button {
    outline: none;
    border: 2px solid #E2CCFF;
    border-radius: 0;
    margin: 0;
    height: auto;
    font-style: inherit;
    color: inherit;
  }
  input {
    border-right: 1px solid #E2CCFF;
    border-radius: 6px 0 0 6px;
    padding: 4px 8px;

    &::placeholder {
      color: #E2CCFF;
    }
  }
  button {
    border-left: 1px solid #E2CCFF;
    border-radius: 0 6px 6px 0;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  button {
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}