@import '../../variables.scss';

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $page-background;
  border-radius: 6px;

  .navSection {
    display: flex;
    flex-direction: column;

    .navOption {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 1 / 1;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      background: none;
      -webkit-tap-highlight-color: transparent;
      color: hsl(0, 0%, 40%);

      svg {
        width: 24px;
        height: 24px;
      }

      .photo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    
      &:hover {
        color: darken(#e2ccff, 30);
      }

      &:active {
        background-color: lighten(#e2ccff, 3);
      }
    }

    .selected {
      background-color: #e2ccff;
    }
  }
}