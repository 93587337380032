.branches {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  margin: 16px;

  .branch {
    background-color: hsl(0, 0%, 95%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-radius: 6px;
    cursor: pointer;

    .branchName {
      font-size: xx-large;
    }

    &:hover {
      background-color: hsl(0, 0%, 93%);
    }

    &:active {
      background-color: hsl(0, 0%, 90%);
    }
  }
}

.createBranchForm {
  display: flex;
  max-width: 600px;
  margin: 16px auto;
  padding: 32px;
  align-items: stretch;

  .branchName {
    max-width: 100%;
    flex-grow: 1;
  }
}