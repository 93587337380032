@import '../../variables.scss';

.layout {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: calc(100vh - 16px);
  gap: 8px;
}

.subnav_hide {
  grid-template-columns: 0 1fr;
  gap: 0;
}

.page {
  position: relative;
  background-color: $page-background;
  color: hsl(0, 0%, 25%);
  border-radius: 6px;
  overflow-y: overlay;
}