@import '../../variables.scss';

.container {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;

  .actionButtons {
    display: flex;
  }
}

.cartTable {

  tr {
    grid-template-columns: 6fr 4fr 10fr 32fr repeat(4, 9fr) 12fr;

    &:hover {
      z-index: 1;
      outline: 1px solid hsl(268, 100%, 70%);
    }
  }

  td:last-child {
    text-align: right;
  }

  thead, tfoot {
    td {
      background-color: #F1E5FF;
    }
  }

  tbody {
    td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8) {
      text-align: right;
    }
  }

  tfoot {
    td {
      text-align: right;
      font-weight: bold;
    }
    tr {
      grid-template-columns: 88fr 12fr;
    }
  }

  .buttonBlock {
    padding: 0;

    button {
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .errorRow {
    td {
      background-color: hsl(0, 100%, 90%);
    }
  }
}

.customerDetails {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.editableBlock {
  padding: 0;
  span {
    box-sizing: border-box;
    padding: 4px 8px;
    width: 100%;
    height: 100%;
    
    &:hover {
      background-color: #F1E5FF;
    }
    &[contenteditable="true"] {
      background-color: #e4ccff;
    }
  }
}